export default [
  {
    title: 'Политика конфиденциальности',
    description:
      'Мы тренируем коммуникативный навык с первого дня даже не зная ни одного слова. Хотя вот список слов, которые ты точно знаешь.',
    link: 'https://www.google.com/'
  },
  {
    title: 'Реквизиты',
    description:
      "ИП ПАНТЕЛЕЕВ ФРАНТИШЕК ГРИГОРЬЕВИЧ ИНН: 775122639703,ОГРН: 319774600402511,КПП ,Р/С: 40802810800001120865,Банк: АО 'ТИНЬКОФФ БАНК',БИК: 044525974,К/С: 30101810145250000974",
    link: 'href'
  }
];
